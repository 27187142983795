<template>
  <div
    class="card"
    :class="classObject"
  >
    <div class="card-body">
      <h5 class="card-title">
        {{ equipment.equipment_name }}
      </h5>
      <h6
        class="card-subtitle mb-2"
        :class="steteObject"
      >
        {{ getState() }}
      </h6>
    </div>
  </div>
</template>

<script>
import moment from "moment";
const TIMEOUT = 180000;
const PATLITE_OUT_OF_RANGE = "なし";
const PATLITE_TIMEOUT = "通信断";
const PATLITE_RUNNING = "稼働";
const PATLITE_RUNNNING_STOPPED = "パレット交換中";
const PATLITE_STOPPED = "停止";
const PATLITE_DISORDER = "異常";
const PATLITE_WAIT = "待機";

export default {
  name: "EquipmentCard",
  props: { equipment: Object },
  computed: {
    classObject() {
      const state = this.getState();
      return {
        "bg-success": state === PATLITE_RUNNING,
        "bg-secondary": state === PATLITE_RUNNNING_STOPPED,
        "bg-warning": state === PATLITE_STOPPED,
        "bg-danger": state == PATLITE_DISORDER,
        "text-white":
          state === PATLITE_RUNNING ||
          state === PATLITE_DISORDER ||
          state === PATLITE_RUNNNING_STOPPED,
      };
    },
    steteObject() {
      const state = this.getState();
      return {
        "text-muted": state === PATLITE_WAIT || state === PATLITE_STOPPED,
        "text-danger": state === PATLITE_TIMEOUT,
        "text-white-50":
          state === PATLITE_RUNNING ||
          state === PATLITE_DISORDER ||
          state === PATLITE_RUNNNING_STOPPED,
      };
    },
  },
  methods: {
    getState() {
      let p_state = PATLITE_OUT_OF_RANGE;
      const patlite = this.equipment.patlite;
      if (patlite && moment().diff(patlite.time) > TIMEOUT) {
        p_state = PATLITE_TIMEOUT;
        this.isDisconnected = true;
      } else if (patlite) {
        if (patlite.green > 0 && !(patlite.green > 0 && patlite.yellow > 0)) {
          p_state = PATLITE_RUNNING;
        } else if (patlite.green > 0 && patlite.yellow > 0) {
          p_state = PATLITE_RUNNNING_STOPPED;
        } else if (patlite.yellow > 0) {
          p_state = PATLITE_STOPPED;
        } else if (patlite.red > 0) {
          p_state = PATLITE_DISORDER;
        } else {
          p_state = PATLITE_WAIT;
        }
      }
      return p_state;
    },
  },
};
</script>
