import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAXcs4Sfts1TohcWUNYLIUkSDB1qEaHF5o",
    authDomain: "hiroshima-sandbox-2.firebaseapp.com",
    databaseURL: "https://hiroshima-sandbox-2.firebaseio.com",
    projectId: "hiroshima-sandbox-2",
    storageBucket: "hiroshima-sandbox-2.appspot.com",
    messagingSenderId: "122100540904",
    appId: "1:122100540904:web:3ee5464825d96de87e705d",
    measurementId: "G-X2V1P037V9"
};

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe()
            resolve(user);
        }, reject);
    });
};

export default firebase;
