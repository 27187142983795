<template>
  <div class="row m-1">
    <div
      v-for="equipment in equipments"
      :key="equipment.equipment_id"
      class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 g-1"
    >
      <EquipmentCard :equipment="equipment" />
    </div>
  </div>
  <button
    class="m-2 btn btn-primary"
    @click="userLogout"
  >
    <i class="bi bi-box-arrow-right me-1" />ログアウト
  </button>
</template>

<script>
import firebase from "../firebase.js";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import EquipmentCard from "@/components/EquipmentCard.vue";
const db = firebase.firestore();

export default {
  name: "DashboardView",
  components: {
    EquipmentCard,
  },
  data() {
    return {
      unsubscribe: null,
      equipments: [],
    };
  },
  mounted() {
    document.title = "ダッシュボード";
    const q = query(collection(db, "equipments"), orderBy("company_id"));
    this.unsubscribe = onSnapshot(q, (snapShot) => {
      const equips = [];
      snapShot.forEach((doc) => {
        let equip = doc.data();
        equip["id"] = doc.id;
        equips.push(equip);
      });
      this.equipments = equips;
      // snapShot.docChanges().forEach((change) => {
      //   if (change.type === "added") {
      //     const equipment = change.doc.data();
      //     equipment["equipment_id"] = change.doc.id;
      //     this.equipments.push(equipment);
      //   }
      //   if (change.type === "modified") {
      //     const equipment = change.doc.data();
      //     equipment["equipment_id"] = change.doc.id;
      //     this.equipments.filter(e => e.equipment_id === equipment.equipment_id)[0] = equipment;
      //   }
      //   if (change.type === "removed") {
      //     const equipment_id = change.doc.id;
      //     this.equipments.unshift(this.equipments.filter(e => e.equipment_id === equipment_id));
      //   }
      // });
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
  methods: {
    userLogout() {
      getAuth()
        .signOut()
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>
