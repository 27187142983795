<template>
  <main class="container">
    <form
      class="form-login needs-validation"
      novalidate
      @submit.prevent="userLogin"
    >
      <div class="input-group has-validation">
        <label
          for="email"
          class="form-label"
        >メールアドレス</label>
        <input
          id="email"
          v-model="email"
          type="email"
          class="form-control"
          required
          autofocus
          placeholder="username@digital-sol.co.jp"
        >
        <div class="invalid-feedback">
          Please provide a valid city.
        </div>
      </div>
      <input
        v-model="password"
        type="password"
        class="form-control"
        required
        placeholder="password"
      >
      <button
        type="submit"
        class="w-100 btn btn-primary"
      >
        ログイン
      </button>
    </form>
  </main>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
export default {
  name: "LoginView",
  mounted() {
    document.title = "ログイン";
    logEvent(getAnalytics(), "page_view");
  },
  methods: {
    userLogin() {
      signInWithEmailAndPassword(getAuth(), this.email, this.password)
        .then(() => {
          this.$router.push("/");
          logEvent(getAnalytics(), "login");
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.form-login {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
</style>